import React from 'react';
import PortableText from 'react-portable-text';

import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

import { Resource } from './Resource';

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 700,
    color: theme.workwaveBlue,
    marginBottom: '1rem',
    textAlign: 'center',
  },
  subheader: {
    color: theme.workwaveBlue,
    textAlign: 'center',
  },
}));

export const Resources = ({ header, subheader, resources }) => {
  const classes = useStyles();
  const sm = useMediaQuery('(max-width: 707px)');

  return (
    <div style={{ padding: '0', zIndex: 100 }}>
      <Grid
        container
        item
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={1}
      >
        <Grid item>
          <Typography variant='h2' className={classes.header}>
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {subheader?.length &&
            subheader.map((content, index) => (
              <PortableText
                key={index}
                content={content}
                // className={classes.text}
                serializers={{
                  normal: ({ children }) => (
                    <Typography variant='body1' className={classes.subheader}>
                      {children}
                    </Typography>
                  ),
                  code: () => <br style={{ display: sm ? 'none' : 'block' }} />,
                }}
              />
            ))}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='center'
        style={{ padding: '2rem 0' }}
      >
        {resources.map((resource, index) => (
          <Resource key={index} resource={resource} />
        ))}
      </Grid>
    </div>
  );
};
